<template>
  <button type="button" class="navbar-toggler" @click="showSidebar">
    <span class="navbar-toggler-bar bar1"></span>
    <span class="navbar-toggler-bar bar2"></span>
    <span class="navbar-toggler-bar bar3"></span>
  </button>
</template>
<script>
export default {
  name: "navbar-toggle-button",
  methods: {
    showSidebar() {
      this.$store.dispatch('toggleSidebar');
    },
  },
};
</script>
