import axios from './../api';
import { buildQuery } from "../actions";
export default {
    namespaced: true,

    state: {
        template_detail: {}
    },

    mutations: {
        TEMPLATE_DETAIL(state, val) {
            state.template_detail = val;
        }
    },

    getters: {
        template_detail: state => state.template_detail
    },

    actions: {

        async fetch(ctx, params) {
            try {
                return await axios.get('/api/admin/v1/default_email_templates' + buildQuery(params));
            } catch (error) {
                console.log(error);
            }
        },

        detail(ctx, id) {
            let {commit, state} = ctx;
            axios.get('/api/admin/v1/default_email_templates/' + id)
                .then(response => {
                    commit('TEMPLATE_DETAIL', response.data.data);
                })
                .catch(error => {
                    console.error('TEMPLATE_DETAIL: ', error);
                });
        },

        update(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/api/admin/v1/default_email_templates/${obj.id}` , obj)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                });
        },

    },
}

