export default [
  {
    path: "/events",
    meta: { roles: [1]},
    redirect: "/events/list",
    component: () =>
      import("src/components/Dashboard/Layout/DashboardLayout.vue"),
    children: [
      {
        path: "list",
        name: "ListEvents",
        component: () =>
          import("src/components/Dashboard/Views/Event/All-Event.vue"),
      },
      {
        path: "create",
        name: "CreateEvent",
        component: () =>
          import("src/components/Dashboard/Views/Event/Create-Event.vue"),
      },
      {
        path: ":id",
        name: "EditEvent",
        component: () =>
          import("src/components/Dashboard/Views/Event/Edit-Event.vue"),
      },
    ],
  },
];
