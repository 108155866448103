import axios from './../api';
import { buildQuery } from "../actions";
export default {
  namespaced: true,
  state: {
    organizers: [],
    organizerDetail: []
  },

  getters: {

  },

  mutations: {
    ORGANIZERS(state, val) {
      state.organizers = val;
    },

    ORGANIZER_DETAIL(state, val) {
      state.organizerDetail = val;
    }
  },

  actions: {

    async fetch(ctx, params) {
      try {
        return await axios.get('/api/admin/organizers' + buildQuery(params));
      } catch (error) {
        console.log(error);
      }
    },

    detail(ctx, id) {
      let { commit, state } = ctx;
      axios.get('/api/admin/organizers/' + id)
        .then(response => {
          commit('ORGANIZER_DETAIL', response.data.data);
        })
        .catch(error => {
          console.error('fetchOrganizerDetail: ', error);
        });
    },

    store(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/organizers', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })

    },

    update(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/organizers/' + data.get('id'), data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })

    },

    delete(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/organizers/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },



  },
}
