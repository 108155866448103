import axios from './../api';
import { buildQuery } from "../actions";
export default {
  namespaced: true,
  state: {
    catalogs: []
  },

  getters: {

  },

  mutations: {
    CATALOGS(state, val) {
      state.catalogs = val;
    }

  },

  actions: {

    catalogs(ctx, payload) {
        let {commit, state} = ctx;
        axios.get('/api/admin/catalogs', { params: payload })
        .then(response => {
            commit('CATALOGS', response.data.data);
        });
    },

    email_templates(ctx, payload) {
      let {commit, state} = ctx;
      axios.get('/api/admin/email_templates')
        .then(response => {
          commit('EMAIL_TEMPLATES', response.data.data);
        });
    },

    config(ctx, payload) {
      let {commit, state} = ctx;
      axios.get('/api/admin/events/config', { params: payload })
        .then(response => {
          commit('CONFIG', response.data.data);
        })
        .catch(error => {
          console.error('fetchEvents: ', error);
        });
    },

    async fetch(ctx, params) {
      try {
        return await axios.get('/api/admin/events' + buildQuery(params));
      } catch (error) {
        console.log(error);
      }
    },

    async fetchCategory(ctx, params) {
      try {
        return await axios.get('/api/admin/event-categories' + buildQuery(params));
      } catch (error) {
        console.log(error);
      }
    },

    async fetchAddon(ctx, params) {
      try {
        return await axios.get('/api/admin/event-addons' + buildQuery(params));
      } catch (error) {
        console.log(error);
      }
    },

    detail(ctx, id) {
      let { commit, state } = ctx;
      axios.get('/api/admin/events/' + id)
        .then(response => {
          commit('EVENT_DETAIL', response.data.data);
        })
        .catch(error => {
          console.error('fetchEventDetail: ', error);
        });
    },

    categoryDetail(ctx, id) {
      let { commit, state } = ctx;
      axios.get('/api/admin/event-categories/' + id)
        .then(response => {
          commit('CATEGORY_DETAIL', response.data.data);
        })
        .catch(error => {
          console.error('fetchCategoryDetail: ', error);
        });
    },

    addonDetail(ctx, id) {
      let { commit, state } = ctx;
      axios.get('/api/admin/event-addons/' + id)
        .then(response => {
          commit('ADDON_DETAIL', response.data.data);
        })
        .catch(error => {
          console.error('fetchAddonDetail: ', error);
        });
    },

    seasons(ctx, id) {
      let { commit, state } = ctx;
      axios.get('/api/admin/events/' + id + '/seasons')
        .then(response => {
          commit('EVENT_SEASONS', response.data.data);
        })
        .catch(error => {
          console.error('fetchSeasons: ', error);
        });
    },

    store(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/events', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    storeCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/event-categories', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    storeAddon(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/event-addons', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    storeVariant(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/addon-variants', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },

    storeDiscountGroup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/event-discounts', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    storeEmailTemplate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/email_templates', data)
          .then(response => {
            if (response.status != 200) throw response.data.message;
            resolve(response.data);
          })
          .catch(error => reject(error));
      })
    },

    storeExtendField(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/event-extend-fields', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    createOrUpdateSeason(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/events/seasons', data)
          .then(response => {
            if (response.status != 200) throw response.data.message;
            resolve(response.data);
          })
          .catch(error => reject(error))
      })
    },

    createOrUpdatePrice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/events/seasons/prices', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    storePersonalDiscount(ctx, data) {
        return new Promise((resolve, reject) => {
          axios
            .post('/api/admin/events/'+ data.event_id +'/personal/discounts', data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
    },

    update(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/events/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateCategory(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/event-categories/update', formData)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateAddon(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/event-addons/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateVariant(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/addon-variants/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    deleteVariant(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/admin/addon-variants/${id}`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
      
    },

    updateDiscountGroup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/event-discounts/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateEmailTemplate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/email_templates/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateExtendField(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/event-extend-fields/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    delete(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/events/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    deleteCategory(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/event-categories/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    deleteAddon(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/event-addons/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    deleteDiscount(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/event-discounts/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    deleteEmailTemplate(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/email_templates/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    deleteSeason(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/events/seasons/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    getRegulation(ctx, event_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/admin/events/${event_id}/rules`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateRegulation(ctx, {event_id, data}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/admin/events/${event_id}/rules`, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    async fetchBannerAds({commit}, params) {
      try {
        return await axios.get('/api/admin/banner_ads' + buildQuery(params));
      } catch (error) {
        console.log(error);
      }
    },

    fetchTypeBannerAds({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/admin/banner_ads/types')
        .then(response => {
          commit('SET_TYPES_BANNER', response.data.data);
        })
        .catch(error => {
          console.error('fetchEventDetail: ', error);
        });
      })
    },

    createBannerAds({commit}, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/banner_ads', formData)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    fetchBannerAdsDetail({commit}, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/admin/banner_ads/${id}`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateBannerAds({commit}, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/banner_ads/update', formData)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    deleteBannerAds({commit}, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/admin/banner_ads/${id}`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    storeCoupons({commit}, data) {
        return new Promise((resolve, reject) => {
          axios
            .post('/api/admin/events/' + data.id + '/coupons', data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
    },

    storeShortCode({commit}, data) {
        return new Promise((resolve, reject) => {
          axios
            .post('/api/admin/email_templates/shortcodes', data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
    },

    deleteShortCode({commit}, id) {
        return new Promise((resolve, reject) => {
          axios
            .delete('/api/admin/email_templates/shortcodes/' + id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
    },

    getCatalogs({commit}, event_id) {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/admin/events/' + event_id + '/catalogs')
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
    },

  },
}
