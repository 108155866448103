export default [
  {
    path: "/organizers",
    meta: { roles: [1] },
    redirect: "/organizers/list",
    component: () =>
      import("src/components/Dashboard/Layout/DashboardLayout.vue"),
    children: [
      {
        path: "list",
        name: "ListTicketOrganizers",
        component: () =>
          import("src/components/Dashboard/Views/Organizer/All-Organizer.vue"),
      },
      {
        path: "create",
        name: "CreateTicketOrganizers",
        component: () =>
          import(
            "src/components/Dashboard/Views/Organizer/Create-Organizer.vue"
          ),
      },
      {
        path: ":id",
        name: "EditTicketOrganizers",
        component: () =>
          import("src/components/Dashboard/Views/Organizer/Edit-Organizer.vue"),
      },
    ],
  },
];
