export default [
  {
    path: '/aff/commission',
    meta: { roles: [1] },
    redirect: '/aff/commission/list',
    component: () => import('src/components/Dashboard/Layout/DashboardLayout.vue'),
    children: [
      {
        path: 'list',
        name: 'aff-commission-list',
        component: () => import('src/components/Dashboard/Views/Affiliate/Commission/All-Commission.vue'),
        meta: {
          pageTitle: 'Commission'
        }
      }
    ]
  }
];