export default [
  {
    path: '/withdrawals',
    meta: { roles: [1, 4] },
    redirect: '/withdrawals/list',
    component: () => import('src/components/Dashboard/Layout/DashboardLayout.vue'),
    children: [
      {
        path: 'list',
        name: 'aff-withdrawals-list',
        component: () => import('src/components/Dashboard/Views/Affiliate/Withdrawal/All-Withdrawal.vue'),
        meta: {
          pageTitle: 'Đối tác'
        }
      },
      {
        path: ':id',
        name: 'aff-withdrawals-edit',
        component: () => import('src/components/Dashboard/Views/Affiliate/Withdrawal/Edit-Withdrawal.vue'),
        meta: {
          pageTitle: 'Yêu cầu rút tiền'
        }
      }
    ]
  }
];