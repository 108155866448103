import axios from "./../api";
import { buildQuery } from "../actions";
export default {
    namespaced: true,

    state: {
        testimonials: [],
        testimonial_detail: {},
    },

    mutations: {
        TESTIMONIALS(state, val) {
            state.testimonials = val;
        },

        TESTIMONIAL_DETAIL(state, val) {
            state.testimonial_detail = val;
        },
    },

    getters: {
        testimonials: (state) => state.testimonials,
        testimonial_detail: (state) => state.testimonial_detail,
    },

    actions: {
        async fetch(ctx, params) {
            try {
                return await axios.get(
                    "/api/admin/v1/testimonials" + buildQuery(params)
                );
            } catch (error) {
                console.log(error);
            }
        },
        detail(ctx, id) {
            let { commit, state } = ctx;
            axios
                .get("/api/admin/v1/testimonials/" + id)
                .then((response) => {
                    commit("TESTIMONIAL_DETAIL", response.data.data);
                })
                .catch((error) => {
                    console.error("fetchEvents: ", error);
                });
        },

        store(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/admin/v1/testimonials`, obj)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },

        update(ctx, data) {
          return new Promise((resolve, reject) => {
              axios
              .post('/api/admin/v1/testimonials/' + data.get('id'), data)
              .then(response => resolve(response.data))
              .catch(error => reject(error))
              });
         },

        destroy(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/api/admin/v1/testimonials/${id}`)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },
    },
};
