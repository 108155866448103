import axios from './../api';
import { buildQuery } from "../actions";
export default {
  namespaced: true,
  state: {
    email_templates: [],
    emailTemplateDetail: [],
  },

  mutations: {
    EMAIL_TEMPLATES(state, val) {
      state.email_templates = val;
    },

    EMAIL_TEMPLATE_DETAIL(state, val) {
      state.emailTemplateDetail = val;
    }

  },

  actions: {

    async fetchEmailTemplate(ctx, parmas) {
      try {
        return await  axios.get('/api/admin/email_templates/list' + buildQuery(parmas));
      } catch (error) {
        console.log(error);
      }
    },

    emailTemplateDetail(ctx, id) {
      let { commit, state } = ctx;
      axios.get('/api/admin/email_templates/' + id)
        .then(response => {
          commit('EMAIL_TEMPLATE_DETAIL', response.data.data);
        });
    },

    storeEmailTemplate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/email_templates', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateEmailTemplate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/email_templates/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    deleteEmailTemplate(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/email_templates/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    }

  }
}
