<template>
  <div :class="{ 'nav-open': show }">
    <router-view name="header"></router-view>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    <router-view name="footer"></router-view>
    <loading :active="showLoading" />
  </div>
</template>

<script>
// Loading some plugin css asynchronously
import "sweetalert2/dist/sweetalert2.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
export default {
  components: { Loading },
  computed: {
    show() {
      return this.$store.state.showSidebar;
    },
    showLoading() {
      return this.$store.state.loading;
    }
  },
};
</script>
