export default [
    {
        id: 1,
        name: 'Upload Request from 23. Oct. 2023',
        total: 1,
        createdAt: '22. Mar. 2022, 06:53',
        type: 'folder',
        link: 'storage/file/Upload Request from 23. Oct. 2023',
        is_children: true
    },
    {
        id: 2,
        parent_id: 1,
        name: 'Folder-1',
        total: 4,
        createdAt: '23. Oct. 2023, 08:53',
        type: 'folder',
        link: 'storage/file/Folder-1'
    },
    {
        id: 3,
        parent_id: 1,
        name: 'Folder-2',
        total: 1,
        createdAt: '10. Oct. 2023, 08:00',
        type: 'folder',
        link: 'storage/file/Folder-2',
        is_children: true
    },
    {
        id: 18,
        parent_id: 3,
        name: 'Folder-children-2',
        total: 1,
        createdAt: '10. Oct. 2023, 08:00',
        type: 'folder',
        link: 'storage/file/Folder-children-2'
    },
    {
        id: 4,
        parent_id: 1,
        name: 'School Report',
        type: 'file',
        createdAt: '22. Mar. 2022, 06:49',
        size: '3.35 MB',
        type_file: 'PDF',
        link: 'storage/file/School_Report'
    },
    {
        id: 5,
        name: 'Upload Image',
        total: 5,
        createdAt: '03. Oct. 2023, 06:45',
        type: 'folder',
        link: 'storage/file/Upload_Image',
        is_children: true
    },
    {
        id: 6,
        parent_id: 5,
        name: 'List-images',
        total: 1,
        createdAt: '10. Oct. 2023, 08:00',
        type: 'folder',
        link: 'storage/file/List-images'
    },
    {
        id: 7,
        parent_id: 5,
        name: 'School Report',
        type: 'file',
        createdAt: '22. Mar. 2022, 06:49',
        size: '3.35 MB',
        type_file: 'PDF',
        link: 'storage/file/School_Report'
    },
    {   
        id: 8,
        parent_id: 5,
        name: 'Hình-1.jpeg',
        img_url: 'https://cdnphoto.dantri.com.vn/2qWyLSYXFQsqNFuzzVTY8DvLp8E=/zoom/1200_630/2021/08/28/develsixteenfrontquarter-crop-1630129443135.jpeg',
        type: 'image',
        size: '400KB',
        createdAt: '03. Oct. 2023, 06:45',
        link: 'storage/file/Hình-1.jpeg'
    },
    {
        id: 9,
        parent_id: 5,
        name: 'Hình-2.jpg',
        img_url: 'https://autopro8.mediacdn.vn/2021/10/2/photo-3-16331375538842003973831.jpg',
        type: 'image',
        size: '345KB',
        createdAt: '03. Oct. 2023, 06:45',
        link: 'storage/file/Hình-2.jpg'
    },
    {
        id: 10,
        name: 'Bai-tap',
        createdAt: '22. Mar. 2022, 06:49',
        type: 'file',
        size: '4MB',
        type_file: 'word',
        link: 'storage/file/Bai-tap'
    },
    {
        id: 11,
        name: 'Ôn thi',
        createdAt: '22. Mar. 2022, 06:49',
        type: 'file',
        size: '9MB',
        type_file: 'pdf',
        link: 'storage/file/on-thi'
    },
    {
        id: 12,
        name: 'Shared Folder',
        total: 4,
        createdAt: '22. Mar. 2022, 06:53',
        type: 'folder',
        link: 'storage/file/Shared_Folder'
    },
    {
        id: 13,
        name: 'Documents',
        total: 10,
        createdAt: '22. Mar. 2022, 06:49',
        type: 'folder',
        link: 'storage/file/Documents'
    },
    {
        id: 14,
        name: 'Documents',
        createdAt: '22. Mar. 2022, 06:49',
        type: 'file',
        size: '10MB',
        type_file: 'avif',
        link: 'storage/file/Documents'
    },
    {
        id: 15,
        name: 'Hình-2.jpg',
        img_url: 'https://autopro8.mediacdn.vn/2021/10/2/photo-3-16331375538842003973831.jpg',
        type: 'image',
        size: '345KB',
        createdAt: '03. Oct. 2023, 06:45',
        link: 'storage/file/Hình-2.jpg'
    }
]