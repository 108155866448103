export default [
    {
        path: "/banner-ads",
        meta: { roles: [1] },
        component: () =>
          import("src/components/Dashboard/Layout/DashboardLayout.vue"),
        children: [
          {
            path: '',
            name: "all-banner-ads",
            component: () => import("src/components/Dashboard/Views/Event/BannerAds/All-BannerAds.vue")
          },
          {
            path: 'create',
            name: "create-banner-ads",
            component: () => import("src/components/Dashboard/Views/Event/BannerAds/Create-BannerAds.vue")
          },
          {
            path: ':id',
            name: "edit-banner-ads",
            component: () => import("src/components/Dashboard/Views/Event/BannerAds/Edit-BannerAds.vue")
          }
        ]
    }
]