import axios from './../api';
import { buildQuery } from "../actions";
export default {
  namespaced: true,

  state: {
    inventories: []
  },

  getters: {

  },

  mutations: {
    INVENTORIES(state, val) {
      state.inventories = val;
    }
  },

  actions: {

    fetch(ctx, event_id) {
        return new Promise((resolve, reject) => {
          axios
            .get(`/api/admin/inventories?event_id=${event_id}`)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },

    store(ctx, obj) {
        return new Promise((resolve, reject) => {
          axios
            .post(`/api/admin/inventories`, obj)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
    },

  },
}
