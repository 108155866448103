import axios from './../api';
import { buildQuery } from "../actions";
export default {
    namespaced: true,

    state: {
        faqs: [],
        faq_detail: {}
    },

    mutations: {
        FAQS(state, val) {
            state.faqs = val;
        },

        FAQ_DETAIL(state, val) {
            state.faq_detail = val;
        }
    },

    getters: {
        faqs: state => state.faqs,
        faq_detail: state => state.faq_detail
    },

    actions: {

      async fetch(ctx, params) {
        try {
          return await axios.get('/api/admin/v1/faqs' + buildQuery(params));
        } catch (error) {
          console.log(error);
        }
      },
        detail(ctx, id) {
            let {commit, state} = ctx;
            axios.get('/api/admin/v1/faqs/' + id)
            .then(response => {
                commit('FAQ_DETAIL', response.data.data);
            })
            .catch(error => {
                console.error('fetchEvents: ', error);
            });
        },

        store(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/admin/v1/faqs`, obj)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                });
        },

        update(ctx, obj) {
          console.log(obj)
            return new Promise((resolve, reject) => {
                axios
                .post(`/api/admin/v1/faqs/${obj.id}` , obj)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
                });
        },

        destroy(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/api/admin/v1/faqs/${id}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                });
        }

    },
}

