
import axios from './../api';
import { buildQuery } from "../actions";
export default {
    namespaced: true,

    state: {
        sponsors: [],
        sponsor_detail: {}
    },

    mutations: {
        SPONSORS(state, val) {
            state.sponsors = val;
        },

        SPONSOR_DETAIL(state, val) {
            state.sponsor_detail = val;
        }
    },

    getters: {
        sponsors: state => state.sponsors,
        sponsor_detail: state => state.sponsor_detail
    },

    actions: {

      async fetch(ctx, params) {
        try {
          return await axios.get('/api/admin/v1/sponsors' + buildQuery(params));
        } catch (error) {
          console.log(error);
        }
      },
        detail(ctx, id) {
            let {commit, state} = ctx;
            axios.get('/api/admin/v1/sponsors/' + id)
            .then(response => {
                commit('SPONSOR_DETAIL', response.data.data);
            })
            .catch(error => {
                console.error('fetchEvents: ', error);
            });
        },

        store(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/admin/v1/sponsors`, obj)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                });
        },

        update(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                .post('/api/admin/v1/sponsors/' + data.get('id'), data)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
                });
        },

        destroy(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/api/admin/v1/sponsors/${id}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                });
        },


    },
}

