export default [
    {
      path: '/media',
      meta: { roles: [1] },
      component: () => import('src/components/Dashboard/Layout/DashboardLayout.vue'),
      children: [
        {
          path: '',
          name: 'media',
          component: () => import('src/components/Dashboard/Views/Media/All-Media.vue')
        },
        
      ]
    }
  ];