export default [
    {
        path: "/testimonials",
        meta: {
            roles: [1, 5]
        },
      redirect: "/testimonials",
      component: () => import("src/components/Dashboard/Layout/DashboardLayout.vue"),
      children: [
          {
              path: "list",
              name: "AllTestimonials",
              meta: {
                pageTitle: 'Danh sách Testimonials'
              },
              component: () => import("src/components/Dashboard/Views/Testimonials/All-Testimonial.vue"),
          },
          {
              path: ":id",
              name: "EditTestimonials",
              meta: {
                pageTitle: 'Cập nhật TestimonialS'
              },
              component: () => import("src/components/Dashboard/Views/Testimonials/Edit-Testimonial.vue"),
          }
      ]
  }
];
