<template>
  <navbar navbar-menu-classes="navbar-right">
    <template slot="navbar-menu">
      <router-link to="/register" class="nav-item">
        <a class="nav-link">
          <i class="nc-icon nc-book-bookmark"></i> Đăng ký
        </a>
      </router-link>
      <router-link to="/login" class="nav-item">
        <a class="nav-link">
          <i class="nc-icon nc-tap-01"></i> Đăng nhập
        </a>
      </router-link>

    </template>
  </navbar>
</template>

<script>
  import {Navbar} from 'src/components/UIComponents'
  export default {
    name: 'main-navbar',
    components: {
      Navbar
    }
  }
</script>

<style scoped>

</style>
