import axios from './../api';
import { buildQuery } from "../actions";
export default {
    namespaced: true,

    state: {
        oauth_clients: []
    },

    mutations: {
        OAUTH_CLIENTS(state, val) {
            state.oauth_clients = val;
        }
    },

    getters: {
        oauth_clients(state) {
            return state.oauth_clients.map(item => {
                if (item.ips && item.ips.length) {
                    item._ips = item.ips;
                    item.ips = item.ips.join("\n");
                }
                return item;
            });
        }
    },

    actions: {

        fetchClients(ctx) {
            let {commit, state} = ctx;
            axios.get('/api/admin/v1/oauth/clients')
            .then(response => {
                commit('OAUTH_CLIENTS', response.data.data);
            })
            .catch(error => {
                console.error('fetchClients: ', error);
            });
        },

        storeClient(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/admin/v1/oauth/clients`, obj)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                });
        },

        updateClient(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/api/admin/v1/oauth/clients/${obj.id}`, obj)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                });
        }

    },
}
