export default [
  {
    path: '/email-templates',
    meta: { roles: [1] },
    redirect: '/email-templates/list',
    component: () => import('src/components/Dashboard/Layout/DashboardLayout.vue'),
    children: [
      {
        path: 'list',
        name: 'email-templates-list',
        component: () => import('src/components/Dashboard/Views/EmailTemplate/All-EmailTemplate.vue'),
        meta: {
          pageTitle: 'Email Template',
        }
      },
      {
        path: 'create',
        name: 'email-templates-create',
        component: () => import('src/components/Dashboard/Views/EmailTemplate/Create-EmailTemplate.vue'),
        meta: {
          pageTitle: 'Thêm Email Template'
        }
      },
      {
        path: ':id',
        name: 'email-templates-edit',
        component: () => import('src/components/Dashboard/Views/EmailTemplate/Edit-EmailTemplate.vue'),
        meta: {
          pageTitle: 'Chỉnh sửa Email Template'
        }
      }
    ]
  }
];