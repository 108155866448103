import axios from './../api';
import { buildQuery } from "../actions"
export default {
  namespaced: true,
  state: {
    attributes: [],
    attributeDetail: []
  },

  getters: {

  },

  mutations: {
    ATTRIBUTES(state, val) {
      state.attributes = val;
    },

    ATTRIBUTE_DETAIL(state, val) {
      state.attributeDetail = val;
    }
  },

  actions: {

    async fetch(ctx, params) {
      try {
        return await axios.get('/api/admin/attributes' + buildQuery(params));
      } catch (error) {
        console.log(error);
      }
    },

    detail(ctx, id) {
      let { commit, state } = ctx;
      axios.get('/api/admin/attributes/' + id)
        .then(response => {
          commit('ATTRIBUTE_DETAIL', response.data.data);
        })
        .catch(error => {
          console.error('fetchATTRIBUTE_DETAIL: ', error);
        });
    },

    store(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/attributes', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    update(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/attributes/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })

    },

    delete(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/attributes/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    }

  },
}
