import axios from './../api';
import { buildQuery } from "../actions";
export default {
  namespaced: true,
  state: {
    categories: []
  },

  getters: {

  },

  mutations: {
    CATEGORIES(state, val) {
        state.categories = val;
    }

  },

  actions: {

    categories(ctx, payload) {
        let {commit, state} = ctx;
        axios.get('/api/admin/product-categories', { params: payload })
        .then(response => {
            commit('CATEGORIES', response.data.data);
        });
    }

  },
}
