export default {
    namespaced: true,
    state: {
      isShow: false,
    },
    mutations: {
      TOGGLE_LOADER(state) {
        state.isShow = !state.isShow;
      },
    },
    actions: {
      toggleLoader({ commit }) {
        commit("TOGGLE_LOADER");
      },
    },
  };
  