export default {
    namespaced: true,
    state: {
        breadcrumbs: [],
    },
    mutations: {
        SET_BREADCRUMBS(state, payload) {
          state.breadcrumbs = payload;
        },
        RESET(state){
            state.breadcrumbs = [];
        }
    },
  };
  