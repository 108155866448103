export default [
  {
    path: '/aff/partners',
    meta: { roles: [1] },
    redirect: '/aff/partners/list',
    component: () => import('src/components/Dashboard/Layout/DashboardLayout.vue'),
    children: [
      {
        path: 'list',
        name: 'aff-partners-list',
        component: () => import('src/components/Dashboard/Views/Affiliate/Partner/All-Partner.vue'),
        meta: {
          pageTitle: 'Đối tác'
        }
      },
      {
        path: 'create',
        name: 'aff-partners-create',
        component: () => import('src/components/Dashboard/Views/Affiliate/Partner/Create-Partner.vue'),
        meta: {
          pageTitle: 'Thêm đối tác'
        }
      },
      {
        path: ':id',
        name: 'aff-partners-edit',
        component: () => import('src/components/Dashboard/Views/Affiliate/Partner/Edit-Partner.vue'),
        meta: {
          pageTitle: 'Chỉnh sửa đối tác'
        }
      }
    ]
  }
];