export default [
    {
        path: "/sponsors",
        meta: {
            roles: [1, 5]
        },
        redirect: "/sponsors/list",
        component: () => import("src/components/Dashboard/Layout/DashboardLayout.vue"),
        children: [
            {
                path: "list",
                name: "AllSponsors",
                component: () => import("src/components/Dashboard/Views/Sponsor/All-Sponsor.vue"),
            },
            {
                path: ":id",
                name: "EditSponsor",
                component: () => import("src/components/Dashboard/Views/Sponsor/Edit-Sponsor.vue"),
            }
        ]
    }
];
  