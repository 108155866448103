export default [
  {
    path: '/attributes',
    meta: { roles: [1] },
    redirect: '/attributes/list',
    component: () => import('src/components/Dashboard/Layout/DashboardLayout.vue'),
    children: [
      {
        path: 'list',
        name: 'ListAttribute',
        component: () => import('src/components/Dashboard/Views/Attribute/All-Attribute.vue')
      },
      {
        path: 'create',
        name: 'CreateAttribute',
        component: () => import('src/components/Dashboard/Views/Attribute/Create-Attribute.vue')
      },
      {
        path: ':id',
        name: 'EditAttribute',
        component: () => import('src/components/Dashboard/Views/Attribute/Edit-Attribute.vue')
      }
    ]
  }
];