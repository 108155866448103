export default [
  {
      path: "/faqs",
      meta: {
            roles: [1, 5]
        },
      redirect: "/faqs",
      component: () => import("src/components/Dashboard/Layout/DashboardLayout.vue"),
      children: [
          {
              path: "list",
              name: "AllFaqs",
              meta: {
                pageTitle: 'Danh sách FAQs'
              },
              component: () => import("src/components/Dashboard/Views/Faq/All-Faq.vue"),
          },
          {
              path: ":id",
              name: "EditFaqs",
              meta: {
                pageTitle: 'Cập nhật FAQS'
              },
              component: () => import("src/components/Dashboard/Views/Faq/Edit-Faq.vue"),
          }
      ]
  }
];
