export default [
  {
    path: '/aff/groups',
    meta: { roles: [1] },
    redirect: '/aff/groups/list',
    component: () => import('src/components/Dashboard/Layout/DashboardLayout.vue'),
    children: [
      {
        path: 'list',
        name: 'aff-group-list',
        component: () => import('src/components/Dashboard/Views/Affiliate/Group/All-Group.vue'),
        meta: {
          pageTitle: 'Nhóm'
        }
      },
      {
        path: 'create',
        name: 'aff-group-create',
        component: () => import('src/components/Dashboard/Views/Affiliate/Group/Create-Group.vue'),
        meta: {
          pageTitle: 'Thêm nhóm'
        }
      },
      {
        path: ':id',
        name: 'aff-group-edit',
        component: () => import('src/components/Dashboard/Views/Affiliate/Group/Edit-Group.vue'),
        meta: {
          pageTitle: 'Chỉnh sửa nhóm'
        }
      }
    ]
  }
];