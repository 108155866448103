export default [
  {
    path: '/event-categories',
    meta: { roles: [1] },
    redirect: '/event-categories/list',
    component: () => import('src/components/Dashboard/Layout/DashboardLayout.vue'),
    children: [
      {
        path: 'list',
        name: 'ListEventCategory',
        component: () => import('src/components/Dashboard/Views/Event/Category/All-Category.vue'),
        meta: {
          pageTitle: 'Danh mục'
        }
      },
      {
        path: 'create',
        name: 'CreateEventCategory',
        component: () => import('src/components/Dashboard/Views/Event/Category/Create-Category.vue'),
        meta: {
          pageTitle: 'Thêm danh mục'
        }
      },
      {
        path: ':id',
        name: 'EditEventCategory',
        component: () => import('src/components/Dashboard/Views/Event/Category/Edit-Category.vue'),
        meta: {
          pageTitle: 'Chỉnh sửa danh mục'
        }
      }
    ]
  }
];