<template>
  <div
    class="sidebar"
    :data-color="backgroundColor"
    :data-active-color="activeColor"
  >
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot> </slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import "perfect-scrollbar/dist/css/perfect-scrollbar.css";
import { mapState } from "vuex";

export default {
  props: {
    title: {
      type: String,
      default: "Flexbox",
      description: "Sidebar title",
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = ["white", "brown", "black"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Sidebar background color (white|brown|black)",
    },
    activeColor: {
      type: String,
      default: "success",
      validator: (value) => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        "Sidebar active text color (primary|info|success|warning|danger)",
    },
    logo: {
      type: String,
      default: "static/img/vue-logo.png",
      description: "Sidebar Logo",
    },
    // sidebarLinks: {
    //   type: Array,
    //   default: () => [],
    //   description:
    //     "Sidebar links. Can be also provided as children components (sidebar-item)",
    // },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sidebarLinks: [],
    };
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    // async initScrollBarAsync () {
    //   const PerfectScroll = await import('perfect-scrollbar')
    //   PerfectScroll.initialize(this.$refs.sidebarScrollArea)
    // },

    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },
  },
  computed: {
    ...mapState(["sidebarLinks"]),
  },
  mounted() {
    // this.initScrollBarAsync()
    // console.log("data", [...this.$store.state.sidebarLinks]);
    this.sidebarLinks = [...this.$store.state.sidebarLinks];
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
.sidebar .sidebar-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}
.sidebar .nav li > a {
  margin: 5px 15px 0 !important;
}
</style>
