export default [
    {
        path: '/ivip',
        meta: {
            roles: [1, 5]
        },
        redirect: '/ivip/plans',
        component: () => import('src/components/Dashboard/Layout/DashboardLayout.vue'),
        children: [
            {
                path: 'plans',
                name: 'iVipPlans',
                component: () => import('src/components/Dashboard/Views/iVip/All-Plan.vue'),
                meta: {
                    pageTitle: 'Danh sách gói'
                }
            },
            {
                path: 'plans/:id',
                name: 'iVipPlanDetail',
                component: () => import('src/components/Dashboard/Views/iVip/Edit-Plan.vue'),
                meta: {
                    pageTitle: 'Chi tiết gói'
                }
            },
            {
                path: 'gifts/:id',
                name: 'iVipGiftDetail',
                component: () => import('src/components/Dashboard/Views/iVip/Edit-Gift.vue'),
                meta: {
                    pageTitle: 'Chi tiết quà tặng'
                }
            },
            {
                path: 'gifts/create',
                name: 'iVipGiftCreate',
                component: () => import('src/components/Dashboard/Views/iVip/Create-Gift.vue'),
                meta: {
                    pageTitle: 'Chi tiết quà tặng'
                }
            },
            {
                path: 'gifts',
                name: 'iVipGifts',
                component: () => import('src/components/Dashboard/Views/iVip/All-Gift.vue'),
                meta: {
                    pageTitle: 'Danh sách quà tặng'
                }
            },
            {
                path: 'sponsors',
                name: 'Sponsors',
                component: () => import('src/components/Dashboard/Views/iVip/All-Gift.vue'),
                meta: {
                    pageTitle: 'Danh sách quà tặng'
                }
            },
            {
                path: 'sponsors/:id',
                name: 'iVipSponsorDetail',
                component: () => import('src/components/Dashboard/Views/iVip/Edit-Gift.vue'),
                meta: {
                    pageTitle: 'Chi tiết gói'
                }
            },
            {
                path: 'orders',
                name: 'iVipOrders',
                component: () => import('src/components/Dashboard/Views/iVip/All-Order.vue'),
                meta: {
                    pageTitle: 'Danh sách đơn hàng VIP'
                }
            },
            {
                path: 'orders/:id',
                name: 'iVipOrdersDetail',
                component: () => import('src/components/Dashboard/Views/iVip/Edit-Order.vue'),
                meta: {
                    pageTitle: 'Chi tiết đơn hàng VIP'
                }
            },
            {
                path: 'claims',
                name: 'iVipClaims',
                component: () => import('src/components/Dashboard/Views/iVip/All-Claim.vue'),
                meta: {
                    pageTitle: 'Danh sách đơn hàng đổi quà VIP'
                }
            },
            {
                path: 'claims/:id',
                name: 'iVipClaimsDetail',
                component: () => import('src/components/Dashboard/Views/iVip/Edit-Claim.vue'),
                meta: {
                    pageTitle: 'Chi tiết đơn hàng đổi quà VIP'
                }
            },

            // Theme
            {
                path: 'vip-theme',
                name: 'iVipTheme',
                component: () => import('src/components/Dashboard/Views/iVip/All-Theme.vue'),
                meta: {
                    pageTitle: 'Danh sách Theme VIP'
                }
            },
            {
                path: 'vip-theme/:id',
                name: 'iVipThemeDetail',
                component: () => import('src/components/Dashboard/Views/iVip/Edit-Theme.vue'),
                meta: {
                    pageTitle: 'Chi tiết Theme VIP'
                }
            },
            // VipCode
            {
                path: 'codes',
                name: 'iVipCode',
                component: () => import('src/components/Dashboard/Views/iVip/All-Vip-Code.vue'),
                meta: {
                    pageTitle: 'Danh sách VIP Codes'
                }
            },
        ]
    }
];
