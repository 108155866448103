export default [
    {
        path: '/oauth',
        meta: {
            roles: [1]
        },
        redirect: '/oath/clients',
        component: () => import('src/components/Dashboard/Layout/DashboardLayout.vue'),
        children: [
            {
                path: 'clients',
                name: 'OAuthClient',
                component: () => import('src/components/Dashboard/Views/OAuth/All-Clients.vue'),
                meta: {
                    pageTitle: 'Danh sách Clients'
                }
            }
        ]
    }
];