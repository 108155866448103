
import axios from './../api';
import { buildQuery } from "../actions";

export default {

  namespaced: true,

  state: {
    sportDetail: null
  },

  getters: {

  },

  mutations: {

    SPORT_DETAIL(state, data) {
      state.sportDetail = data;
    }
  },

  actions: {

      async fetch(ctx, params) {
          try {
            return await axios.get('/api/admin/sport_types' + buildQuery(params));
          } catch (error) {
            console.log(error);
          }
        },
      
        store(context, form) {
          let {commit, state} = context;
          return new Promise((resolve, reject) => {
            axios.post('/api/admin/sport_types', form)
              .then(resp => {
                resolve(resp.data);
              }, error => {
                reject(error);
              });
          })
        },

        detail(ctx, id) {
          let { commit, state } = ctx;
          axios.get('/api/admin/sport_types/' + id)
            .then(response => {
              commit('SPORT_DETAIL', response.data.data);
            })
            .catch(error => {
              console.error(error);
            });
        },

        update(ctx, formData) {
          return new Promise((resolve, reject) => {
            axios
              .post('/api/admin/sport_types/update', formData)
              .then(response => resolve(response.data))
              .catch(error => reject(error))
          })
        },

        delete(ctx, id) {
            return new Promise((resolve, reject) => {
              axios
                .delete('/api/admin/sport_types/' + id)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
            })
        },

  },
}