export default [
  {
    path: '/event-addons',
    meta: { roles: [1] },
    redirect: '/event-addons/list',
    component: () => import('src/components/Dashboard/Layout/DashboardLayout.vue'),
    children: [
      {
        path: 'list',
        name: 'ListEventAddon',
        component: () => import('src/components/Dashboard/Views/Event/Addon/All-Addon.vue'),
        meta: {
          pageTitle: 'Tiện ích'
        }
      },
      {
        path: 'create',
        name: 'CreateEventAddon',
        component: () => import('src/components/Dashboard/Views/Event/Addon/Create-Addon.vue'),
        meta: {
          pageTitle: 'Thêm tiện ích'
        }
      },
      {
        path: ':id',
        name: 'EditEventAddon',
        component: () => import('src/components/Dashboard/Views/Event/Addon/Edit-Addon.vue'),
        meta: {
          pageTitle: 'Chỉnh sửa tiện ích'
        }
      }
    ]
  }
];