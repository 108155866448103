import data_test from "src/components/Dashboard/Views/Media/data-test.js";

export default {
    namespaced: true,
    state: {
      list: data_test,
      folderCurrent: null,
      fileActive: null
    },
    mutations: {
      SET_FOLDER_CURRENT(state, payload) {
        if(payload) {
          state.folderCurrent = payload
        }else {
          state.folderCurrent = {
            children: state.list.filter(item => !item.parent_id)
          }
        }
      },
      FILE_ACTIVE(state, payload) {
        state.fileActive = payload;
      },
      ADD_FOLDER(state, payload) {
        state.list.push(payload);
      },
      DELETE_FOLDER(state, id) {
        state.list = state.list.filter(folder => folder.id !== id);
      }
    },
    actions: {
      setFolderCurrent({commit}, payload){
        commit('SET_FOLDER_CURRENT', payload)
      },
    },
};
  