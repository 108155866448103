<template>
  <div class="wrapper">
    <side-bar type="sidebar" :logo="this.$store.state.sidebarLogo">
      <user-menu></user-menu>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click="toggleSidebar"> </dashboard-content>

      <!-- <content-footer></content-footer> -->
    </div>
  </div>
</template>
<style lang="css">
  .el-table__header thead tr th {
    background-color: #fff !important;
  }
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import UserMenu from "src/components/UIComponents/SidebarPlugin/UserMenu.vue";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    UserMenu,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },

  created() {
    var login_time = localStorage.getItem("login_time");
    if (!login_time) {
      this.$store.dispatch("authLogout").then((_) => {
        localStorage.setItem("redirect_url", location.href);
        this.$router.push("/login");
      });
    }
    var current_time = new Date().getTime();
    if (current_time - login_time > 7 * 24 * 60 * 60 * 1000) {
      this.$store.dispatch("authLogout").then((_) => {
        localStorage.setItem("redirect_url", location.href);
        this.$router.push("/login");
      });
    } else {
      this.$store.dispatch("initSidebar");
    }
  },
};
</script>
