import group from './group'
import partner from './partner'
import commission from './commission'
import withdrawal from './withdrawal'

const data = [
  ...group,
  ...partner,
  ...commission,
  ...withdrawal
]

export default data