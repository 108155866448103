<template>
  <li class="sidebar-item" :class="{ active: isActive }">
    <router-link to="#" @click.prevent="collapseMenu(link.path)">
      <i :class="link.icon"></i>
      <p>
        {{ link.name }}
        <b
          v-if="link.children"
          class="caret"
          :class="{ rotated: !collapsed }"
        ></b>
      </p>
    </router-link>
    <!-- menu children -->
    <el-collapse-transition v-if="link.children">
      <div v-show="!collapsed" class="collapse-menu">
        <ul class="nav nav-menu-children">
          <li v-for="(menu, index) in link.children" :key="index">
            <router-link :to="menu.path" :class="{active: menu.path === $route.path}">
              {{ menu.name }}
            </router-link>
          </li>
        </ul>
      </div>
    </el-collapse-transition>
  </li>
</template>

<script>
import { ElCollapseTransition } from "element-plus";

export default {
  components: {
    ElCollapseTransition,
  },
  props: {
    menu: {
      type: Boolean,
      default: false,
      description: "Whether item is a menu containing multiple items",
    },
    link: {
      type: Object,
      default: () => {
        return {
          name: "",
          path: "",
          children: [],
        };
      },
      description: "Link object",
    },
  },
  provide() {
    return {
      removeLink: this.removeChild,
    };
  },
  inject: {
    removeLink: { default: null },
    autoClose: { default: false },
  },

  data() {
    return {
      children: [],
      collapsed: true,
    };
  },
  computed: {
    isMenu() {
      return this.children.length > 0 || this.menu === true;
    },
    isActive() {
      return (
        this.children.some((c) => this.$route.path.startsWith(c.path)) ||
        this.$route.path.startsWith(this.link.path)
      );
    },
    changeRoute() {
      return this.$route.path;
    },
  },
  watch: {
    changeRoute(newVal) {
      if (!this.isActive) {
        this.collapsed = true;
      }
    },
  },
  methods: {
    removeChild(item) {
      const tabs = this.children;
      const index = tabs.indexOf(item);
      tabs.splice(index, 1);
    },
    collapseMenu(path) {
      if (path) {
        return this.$router.push(path);
      }
      this.collapsed = !this.collapsed;
    },
    onItemClick() {
      if (this.autoClose) {
        this.$sidebar.showSidebar = false;
      }
    },
  },
  mounted() {
    if (this.link.children) {
      this.children = this.link.children;
    }
    if (this.isActive && this.isMenu) {
      this.collapsed = false;
    }
  },
  beforeRouteLeave() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
};
</script>
<style scoped>
.caret.rotated {
  transform: rotate(180deg);
}
.sidebar-item .nav-menu-children {
  padding-left: 46px;
}
.sidebar-item .nav-menu-children .active {
  color: #2ecc71;
}
</style>
